<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="5">
          <h1 style="text-align: center ">Treasury</h1>
          <p class="text-muted" style="text-align:center">
            Login Administrator
          </p>
          <br />
          <b-card-group>
            <b-card no-body class="p-2">
              <b-card-body>
                <b-row v-if="this.error.form.server != ''">
                  <b-col sm="12">
                    <div>
                      <b-alert show variant="danger" dismissible>
                        {{ this.error.form.server }}
                      </b-alert>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="this.error.form.usernameFailed != ''">
                  <b-col sm="12">
                    <div>
                      <b-alert show variant="danger" dismissible>
                        {{ this.error.form.usernameFailed }}
                      </b-alert>
                    </div>
                  </b-col>
                </b-row>
                <b-form @submit.prevent="login">
                  <b-input-group class="mb-2">
                    <div class="d-flex" style="width: 100%;">
                      <b-input-group-prepend
                        ><b-input-group-text
                          ><i class="icon-user"></i></b-input-group-text
                      ></b-input-group-prepend>
                      <b-form-input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        placeholder="Email"
                        autocomplete="username email"
                        v-model.lazy.trim="$v.form.email.$model"
                      />
                    </div>
                    <span
                      class="helper-text my-2"
                      v-if="
                        $v.form.email.$dirty && !$v.form.email.validCharacters
                      "
                    >
                      Email tidak boleh mengandung special character selain @._-
                    </span>
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <div class="d-flex" style="width: 100%;">
                      <b-input-group-prepend
                        ><b-input-group-text
                          ><i class="icon-lock"></i></b-input-group-text
                      ></b-input-group-prepend>
                      <b-form-input
                        type="password"
                        class="form-control"
                        id="password"
                        name="password"
                        placeholder="Password"
                        autocomplete="current-password"
                        v-model.lazy.trim="$v.form.password.$model"
                      />
                    </div>
                    <span
                      class="helper-text my-2"
                      v-if="!$v.form.password.passwordFormat"
                    >
                      Password tidak boleh mengandung special character selain
                      @_-.!#$%&*?
                    </span>
                  </b-input-group>
                  <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="this.siteKey"
                    :loadRecaptchaScript="true"
                    @verify="onVerify"
                  ></vue-recaptcha>
                  <b-row class="justify-content-center mt-3">
                    <b-col cols="3">
                      <b-button
                        variant="primary"
                        class="px-4"
                        type="submit"
                        :disabled="stateButton"
                      >
                        Login
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card> </b-card-group
          ><br />
          <p class="text-muted" style="text-align:center">
            Treasury - Admin Area
          </p>
          <p class="text-muted" style="text-align:center">&copy; 2019</p>
          <br />
        </b-col>
      </b-row>
      <!-- <b-row>
      <b-col>
        - <a href="#" @click.prevent="deeplink('BeliEmas')">Beli Emas</a> <br>
        - <a href="#" @click.prevent="deeplink('ModuleEmas')">Module Emas</a> <br>
        - <a href="#" @click.prevent="deeplink('BeliPerhiasan')">Beli Perhiasan</a> <br>
        - <a href="#" @click.prevent="deeplink('Amal')">Amal</a> <br>
        - <a href="#" @click.prevent="deeplink('Zakat')">Zakat</a> <br>
        - <a href="#" @click.prevent="deeplink('GiftCard')">Gift Card </a> <br>
        - <a href="#" @click.prevent="deeplink('CetakEmas')">Cetak Emas</a> <br>
        - <a href="#" @click.prevent="deeplink('RencanaEmas')">Rencana Emas</a> <br>
        - <a href="#" @click.prevent="deeplink('DetailPerhiasan?hashedId=bzKJEAyNep67Bjq')">Detail Perhiasan</a> <br>
        - <a href="#" @click.prevent="deeplink('JualEmas')">Jual Emas</a> <br>
        - <a href="#" @click.prevent="deeplink('TarikCelengan')">Tarik Celengan</a> <br>
        - <a href="#" @click.prevent="deeplink('TransferEmas')">Transfer Emas</a> <br>
        - <a href="#" @click.prevent="deeplink('ModuleKripto')">Module Kripto</a> <br>
        - <a href="#" @click.prevent="deeplink('WiraEmas')">WiraEmas</a> <br>
      </b-col>
    </b-row> -->
    </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, helpers, email } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";

const emailRegex = /^[a-zA-Z0-9@._-]*$/;
const passwordRegex = /^[a-zA-Z0-9@_.!#$%&*?-]+$/;

export default {
  name: "Login",
  components: { VueRecaptcha },
  data() {
    return {
      siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
      form: {
        email: "",
        password: "",
        recaptchaToken: ""
      },
      lat: 0,
      long: 0,
      error: {
        message: [],
        status: [],
        headers: [],
        code: "",
        errorMessage: "",
        form: {
          usernameFailed: "",
          credentials: "",
          server: ""
        }
      }
    };
  },
  created() {
    navigator.geolocation.getCurrentPosition(
      position => {
        this.lat = position.coords.latitude;
        this.long = position.coords.longitude;
      },
      error => {
        return Promise.reject(error);
      }
    );
    if (this.location) {
      if (this.loggedIn) {
        this.$router.push({ name: "Home" });
      }
    }
  },
  computed: {
    ...mapGetters(["loggedIn", "location", "password_expired"]),
    ...mapState(["errors"]),
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    stateButton() {
      const state = !(
        this.form?.email &&
        this.form?.password &&
        this.form?.recaptchaToken
      );
      return state;
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        validCharacters: helpers.regex("validCharacters", emailRegex),
        emailFormat: email
      },
      password: {
        required,
        passwordFormat: helpers.regex("passwordFormat", passwordRegex)
      }
    }
  },
  methods: {
    ...mapActions("user", ["getUserLogin"]),
    ...mapMutations(["CLEAR_ERRORS"]),
    chkState(val) {
      const field = this.$v.form[val];
      return !field.$dirty || !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }

      let focused = false;
      component.$children.some(child => {
        focused = this.findFirstError(child);
        return focused;
      });
      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    deeplink(page = "") {
      let ua = navigator.userAgent.toLowerCase();
      let isAndroid = ua.indexOf("android") > -1; // android check
      let isIphone = ua.indexOf("iphone") > -1; // ios check
      if (isIphone == true) {
        let app = {
          launchApp: function() {
            setTimeout(this.openWebApp, 500);
          },
          openWebApp: function() {
            window.location.href = `treasury://splashscreen?nextPage=${page}`;
          }
        };
        app.launchApp();
      } else if (isAndroid == true) {
        let app = {
          launchApp: function() {
            setTimeout(this.openWebApp, 500);
          },
          openWebApp: function() {
            window.location.href = `treasury://splashscreen?nextPage=${page}`;
          }
        };
        app.launchApp();
      } else {
        //navigate to website url
      }
    },
    login() {
      if (this.validate()) {
        if (this.lat === 0 && this.long === 0) {
          return this.$swal.fire(
            "Failed",
            "Please allow your location to access Dashboard",
            "error"
          );
        }

        localStorage.setItem("latitude", this.lat);
        localStorage.setItem("longitude", this.long);

        this.$store
          .dispatch("retrieveToken", {
            username: this.handleEncrypted(this.form.email),
            password: this.handleEncrypted(this.form.password),
            recaptchaToken: this.form.recaptchaToken,
            latitude: this.lat,
            longitude: this.long
          })
          .then(() => {
            this.$router.push({ name: "Home" });
            this.$toasted.success("Login successful!");
          })
          .catch(error => {
            if (error.response) {
              const code = error.response.status;
              const message = error.response.data.message;
              const headers = error.response.headers;

              this.resetRecaptcha();

              this.error.status = code;
              this.error.headers = headers;
              this.error.code = code;
              // this.error.message = message;
              // this.error.form.server = message;

              this.$swal.fire("Failed!", message, "error");
            }
          });
      }
    },
    handleEncrypted(val) {
      const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_KEY_ENCRYPT);
      const iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_IV_ENCRYPT);

      const values = CryptoJS.AES.encrypt(val, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString();

      return values;
    },
    onVerify(response) {
      this.form.recaptchaToken = response;
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
      this.form.recaptchaToken = "";
    }
  },
  destroyed() {
    this.getUserLogin();
  }
};
</script>
<style>
.helper-text {
  color: #cb211f;
  font-size: 10px;
}
</style>
